const warehouseActions = {
  SET_STATE: 'warehouse/SET_STATE',
  ADD: 'warehouse/ADD',
  UPDATE: 'warehouse/UPDATE',
  DELETE: 'warehouse/DELETE',
  GET_LIST: 'warehouse/GET_LIST',
  GET_EDITABLE: 'warehouse/GET_EDITABLE',
  RESET_EDITABLE: 'warehouse/RESET_EDITABLE',
  GET_DETAILS: 'warehouse/GET_DETAILS',
  RESET_DETAILS: 'warehouse/RESET_DETAILS',
  GET_LOOKUP: 'warehouse/GET_LOOKUP',
  SET_SELECTED_LOOKUP: 'warehouse/SET_SELECTED_LOOKUP',
  RESET_SAVING_STATUS: 'warehouse/RESET_SAVING_STATUS',
}

export default warehouseActions
