import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import aagActions from './actions'

const endpoint = '/account_access_groups'

export function* ADD_AAG({ payload }) {
  const { aag } = payload
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, aag)
  if (response) {
    notification.success({
      message: 'Account Access Group Saved',
      description: 'New account access group saved successfully!',
    })
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_AAG({ payload }) {
  const { id, aag } = payload
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, aag)
  if (response) {
    notification.success({
      message: 'Account Access Group Updated',
      description: 'Account Access Group updated successfully!',
    })
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_AAG({ payload }) {
  const { id } = payload
  const { aagList } = yield select(state => state.accountAccessGroup)
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      aagList: aagList.filter(aag => aag.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        totalDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Account Access Group Removed',
      description: 'Account Access Group removed successfully!',
    })
  } else {
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        aagList,
      },
    })
  }
}

export function* GET_AAG_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      loadingAagList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        aagList: response.data.account_access_groups,
        totalDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      loadingAagList: false,
    },
  })
}

export function* GET_EDITABLE_AAG({ payload }) {
  const { id } = payload
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      loadingEditableAag: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        editableAag: response.data.account_access_group,
      },
    })
  } else if (response && response.code === 404) {
    const { aagList } = yield select(state => state.accountAccessGroup)
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        aagList: aagList.filter(aag => aag.id !== id),
      },
    })
  }
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      loadingEditableAag: false,
    },
  })
}

export function* RESET_EDITABLE_AAG() {
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      editableAag: {},
    },
  })
}

export function* GET_AAG_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      loadingAagDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        aagDetails: response.data.account_access_group,
      },
    })
  } else if (response && response.code === 404) {
    const { aagList } = yield select(state => state.accountAccessGroup)
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        aagList: aagList.filter(aag => aag.id !== id),
      },
    })
  }
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      loadingAagDetails: false,
    },
  })
}

export function* RESET_AAG_DETAILS() {
  yield delay(200)
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      aagDetails: {},
    },
  })
}

export function* GET_AAGS_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      aagsLookup: [],
      loadingAagsLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: aagActions.SET_STATE,
      payload: {
        aagsLookup: response.data.account_access_groups,
      },
    })
  }
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      loadingAagsLookup: false,
    },
  })
}

export function* SET_SELECTED_AAG_LOOKUP({ payload }) {
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      selectedAagLookup: payload.selectedAagLookup,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: aagActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(aagActions.ADD_AAG, ADD_AAG),
    takeEvery(aagActions.UPDATE_AAG, UPDATE_AAG),
    takeEvery(aagActions.DELETE_AAG, DELETE_AAG),
    takeLatest(aagActions.GET_AAG_LIST, GET_AAG_LIST),
    takeLatest(aagActions.GET_EDITABLE_AAG, GET_EDITABLE_AAG),
    takeLatest(aagActions.RESET_EDITABLE_AAG, RESET_EDITABLE_AAG),
    takeLatest(aagActions.GET_AAG_DETAILS, GET_AAG_DETAILS),
    takeLatest(aagActions.RESET_AAG_DETAILS, RESET_AAG_DETAILS),
    takeLatest(aagActions.GET_AAGS_LOOKUP, GET_AAGS_LOOKUP),
    takeLatest(aagActions.SET_SELECTED_AAG_LOOKUP, SET_SELECTED_AAG_LOOKUP),
    takeLatest(aagActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
