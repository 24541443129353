const clientActions = {
  SET_STATE: 'client/SET_STATE',
  ADD: 'client/ADD',
  UPDATE: 'client/UPDATE',
  DELETE: 'client/DELETE',
  GET_LIST: 'client/GET_LIST',
  GET_EDITABLE: 'client/GET_EDITABLE',
  RESET_EDITABLE: 'client/RESET_EDITABLE',
  GET_DETAILS: 'client/GET_DETAILS',
  RESET_DETAILS: 'client/RESET_DETAILS',
  RESET_SAVING_STATUS: 'client/RESET_SAVING_STATUS',
}

export default clientActions
