import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  // baseURL: 'https://api.fireprofit.xufire.com/',
  // baseURL: 'https://xufire-profit-api.herokuapp.com/',
  // baseURL: 'http://fireprofitapi-env.eba-ckhxtwhe.us-east-2.elasticbeanstalk.com/',
  baseURL: 'https://api.fireprofit.net/',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers['x-auth-token'] = accessToken
  }
  return request
})

apiClient.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const expectedError =
      error.response && error.response.status >= 400 && error.response.status < 500

    if (expectedError) {
      const { response } = error
      const { data } = response
      if (data) {
        notification.warning({
          message: data.message,
          description: data.description,
        })
      }
    } else {
      notification.warning({
        message: error.message,
        // description: 'An unexpected error occurrred.',
      })
    }

    return Promise.reject(error)
  },
)

export default apiClient
