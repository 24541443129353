import apiClient from 'services/axios'

export default async function getUsers() {
  return apiClient
    .get('/users/lookup')
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => false)
}
