import aagActions from './actions'

const initialState = {
  aagList: [],
  loadingAagList: false,
  editableAag: {},
  loadingEditableAag: false,
  aagDetails: {},
  loadingAagDetails: false,
  aagsLookup: [],
  loadingAagsLookup: false,
  selectedAagLookup: {},
  totalDataCount: 0,
  savingStatus: '',
}

export default function accountAccessGroupReducer(state = initialState, action) {
  switch (action.type) {
    case aagActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
