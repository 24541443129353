import areaActions from './actions'

const initialState = {
  areaList: [],
  loadingAreaList: false,
  editableArea: {},
  loadingEditableArea: false,
  areaDetails: {},
  loadingAreaDetails: false,
  areasLookup: [],
  loadingAreasLookup: false,
  selectedAreaLookup: {},
  totalDataCount: 0,
  savingStatus: '',
}

export default function areaReducer(state = initialState, action) {
  switch (action.type) {
    case areaActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
