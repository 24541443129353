import { all, call, delay, select, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import areaActions from './actions'

const endpoint = '/areas'

export function* ADD_AREA({ payload }) {
  const { area } = payload
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, area)
  if (response) {
    notification.success({
      message: 'Area Saved',
      description: 'New area saved successfully!',
    })
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_AREA({ payload }) {
  const { id, area } = payload
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, area)
  if (response) {
    notification.success({
      message: 'Area Updated',
      description: 'Area updated successfully!',
    })
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_AREA({ payload }) {
  const { id } = payload
  const { areaList } = yield select(state => state.area)
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      areaList: areaList.filter(area => area.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        totalDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Area Removed',
      description: 'Area removed successfully!',
    })
  } else {
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        areaList,
      },
    })
  }
}

export function* GET_AREA_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      loadingAreaList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        areaList: response.data.areas,
        totalDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      loadingAreaList: false,
    },
  })
}

export function* GET_EDITABLE_AREA({ payload }) {
  const { id } = payload
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      loadingEditableArea: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        editableArea: response.data.area,
      },
    })
  } else if (response && response.code === 404) {
    const { areaList } = yield select(state => state.area)
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        areaList: areaList.filter(area => area.id !== id),
      },
    })
  }
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      loadingEditableArea: false,
    },
  })
}

export function* RESET_EDITABLE_AREA() {
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      editableArea: {},
    },
  })
}

export function* GET_AREA_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      loadingAreaDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        areaDetails: response.data.area,
      },
    })
  } else if (response && response.code === 404) {
    const { areaList } = yield select(state => state.area)
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        areaList: areaList.filter(area => area.id !== id),
      },
    })
  }
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      loadingAreaDetails: false,
    },
  })
}

export function* RESET_AREA_DETAILS() {
  yield delay(200)
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      areaDetails: {},
    },
  })
}

export function* GET_AREAS_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      areasLookup: [],
      loadingAreasLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: areaActions.SET_STATE,
      payload: {
        areasLookup: response.data.areas,
      },
    })
  }
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      loadingAreasLookup: false,
    },
  })
}

export function* SET_SELECTED_AREA_LOOKUP({ payload }) {
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      selectedAreaLookup: payload.selectedAreaLookup,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: areaActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(areaActions.ADD_AREA, ADD_AREA),
    takeEvery(areaActions.UPDATE_AREA, UPDATE_AREA),
    takeEvery(areaActions.DELETE_AREA, DELETE_AREA),
    takeLatest(areaActions.GET_AREA_LIST, GET_AREA_LIST),
    takeLatest(areaActions.GET_EDITABLE_AREA, GET_EDITABLE_AREA),
    takeLatest(areaActions.RESET_EDITABLE_AREA, RESET_EDITABLE_AREA),
    takeLatest(areaActions.GET_AREA_DETAILS, GET_AREA_DETAILS),
    takeLatest(areaActions.RESET_AREA_DETAILS, RESET_AREA_DETAILS),
    takeLatest(areaActions.GET_AREAS_LOOKUP, GET_AREAS_LOOKUP),
    takeLatest(areaActions.SET_SELECTED_AREA_LOOKUP, SET_SELECTED_AREA_LOOKUP),
    takeLatest(areaActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
