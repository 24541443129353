const regionActions = {
  SET_STATE: 'region/SET_STATE',
  ADD_REGION: 'region/ADD_REGION',
  UPDATE_REGION: 'region/UPDATE_REGION',
  DELETE_REGION: 'region/DELETE_REGION',
  GET_REGION_LIST: 'region/GET_REGION_LIST',
  GET_EDITABLE_REGION: 'region/GET_EDITABLE_REGION',
  RESET_EDITABLE_REGION: 'region/RESET_EDITABLE_REGION',
  GET_REGION_DETAILS: 'region/GET_REGION_DETAILS',
  RESET_REGION_DETAILS: 'region/RESET_REGION_DETAILS',
  GET_REGIONS_LOOKUP: 'region/GET_REGIONS_LOOKUP',
  SET_SELECTED_REGION_LOOKUP: 'region/SET_SELECTED_REGION_LOOKUP',
  RESET_SAVING_STATUS: 'region/RESET_SAVING_STATUS',
}

export default regionActions
