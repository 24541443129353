import apiClient from 'services/axios'
import store from 'store'

export async function login(email, password) {
  return apiClient
    .post('/auth/login', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data.user
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(() => {
      // if (error.response) {
      //   // Request made and server responded
      //   console.log(error.response.status, error.response.data)
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   console.log('Error:', error.message)
      // }
      return false
    })
}

export async function loadMe() {
  return apiClient
    .get('/auth/me')
    .then(response => {
      if (response) {
        const { accessToken } = response.data.me
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function logout() {
  store.remove('accessToken')
  return true
  // return apiClient
  //   .get('/auth/logout')
  //   .then(() => {
  //   })
  //   .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data.user
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function forgotPassword(email) {
  return apiClient
    .post('/auth/forgotPassword', {
      email,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function resetPassword(password, confirmPassword) {
  return apiClient
    .post('/auth/resetPassword', {
      password,
      confirmPassword,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}
