const maninUnitActions = {
  SET_STATE: 'mainUnit/SET_STATE',
  ADD: 'mainUnit/ADD',
  UPDATE: 'mainUnit/UPDATE',
  DELETE: 'mainUnit/DELETE',
  GET_LIST: 'mainUnit/GET_LIST',
  GET_EDITABLE: 'mainUnit/GET_EDITABLE',
  RESET_EDITABLE: 'mainUnit/RESET_EDITABLE',
  GET_DETAILS: 'mainUnit/GET_DETAILS',
  RESET_DETAILS: 'mainUnit/RESET_DETAILS',
  GET_LOOKUP: 'mainUnit/GET_LOOKUP',
  SET_SELECTED_LOOKUP: 'mainUnit/SET_SELECTED_LOOKUP',
  RESET_SAVING_STATUS: 'mainUnit/RESET_SAVING_STATUS',
}

export default maninUnitActions
