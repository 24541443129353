import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import warehouseActions from './actions'

const endpoint = '/warehouses'

export function* ADD({ payload }) {
  const { warehouse } = payload
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, warehouse)
  if (response) {
    notification.success({
      message: 'Warehouse Saved',
      description: 'New warehouse saved successfully!',
    })
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, warehouse } = payload
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, warehouse)
  if (response) {
    notification.success({
      message: 'Warehouse Updated',
      description: 'Warehouse updated successfully!',
    })
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  const { list } = yield select(state => state.warehouse)
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      list: list.filter(warehouse => warehouse.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        totalDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Warehouse Removed',
      description: 'Warehouse removed successfully!',
    })
  } else {
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        list,
      },
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      loadingList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        list: response.data.warehouses,
        totalDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      loadingList: false,
    },
  })
}

export function* GET_EDITABLE({ payload }) {
  const { id } = payload
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      loadingEditable: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        editable: response.data.warehouse,
      },
    })
  } else if (response && response.code === 404) {
    const { list } = yield select(state => state.warehouse)
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        list: list.filter(warehouse => warehouse.id !== id),
      },
    })
  }
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      loadingEditable: false,
    },
  })
}

export function* RESET_EDITABLE() {
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      editable: {},
    },
  })
}

export function* GET_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      loadingDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        details: response.data.warehouse,
      },
    })
  } else if (response && response.code === 404) {
    const { list } = yield select(state => state.warehouse)
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        list: list.filter(warehouse => warehouse.id !== id),
      },
    })
  }
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      loadingDetails: false,
    },
  })
}

export function* RESET_DETAILS() {
  yield delay(200)
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      details: {},
    },
  })
}

export function* GET_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      lookup: [],
      loadingLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: warehouseActions.SET_STATE,
      payload: {
        lookup: response.data.warehouses,
      },
    })
  }
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      loadingLookup: false,
    },
  })
}

export function* SET_SELECTED_LOOKUP({ payload }) {
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      selectedLookup: payload.selectedLookup,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: warehouseActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(warehouseActions.ADD, ADD),
    takeEvery(warehouseActions.UPDATE, UPDATE),
    takeEvery(warehouseActions.DELETE, DELETE),
    takeLatest(warehouseActions.GET_LIST, GET_LIST),
    takeLatest(warehouseActions.GET_EDITABLE, GET_EDITABLE),
    takeLatest(warehouseActions.RESET_EDITABLE, RESET_EDITABLE),
    takeLatest(warehouseActions.GET_DETAILS, GET_DETAILS),
    takeLatest(warehouseActions.RESET_DETAILS, RESET_DETAILS),
    takeLatest(warehouseActions.GET_LOOKUP, GET_LOOKUP),
    takeLatest(warehouseActions.SET_SELECTED_LOOKUP, SET_SELECTED_LOOKUP),
    takeLatest(warehouseActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
