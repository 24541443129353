import { all, call, delay, select, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import supplierActions from './actions'

const endpoint = '/parties'

export function* ADD({ payload }) {
  const { supplier } = payload
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, supplier)
  if (response) {
    notification.success({
      message: 'Supplier Saved',
      description: 'New supplier saved successfully!',
    })
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, supplier } = payload
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, supplier)
  if (response) {
    notification.success({
      message: 'Supplier Updated',
      description: 'Supplier updated successfully!',
    })
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  const { list } = yield select(state => state.supplier)
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      list: list.filter(supplier => supplier.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        totalDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Supplier Removed',
      description: 'Supplier removed successfully!',
    })
  } else {
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        list,
      },
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload, type: 1 }
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      loadingList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        list: response.data.parties,
        totalDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      loadingList: false,
    },
  })
}

export function* GET_EDITABLE({ payload }) {
  const { id } = payload
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      loadingEditable: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        editable: response.data.party,
      },
    })
  } else if (response && response.code === 404) {
    const { list } = yield select(state => state.supplier)
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        list: list.filter(supplier => supplier.id !== id),
      },
    })
  }
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      loadingEditable: false,
    },
  })
}

export function* RESET_EDITABLE() {
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      editable: {},
    },
  })
}

export function* GET_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      loadingDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        details: response.data.party,
      },
    })
  } else if (response && response.code === 404) {
    const { list } = yield select(state => state.supplier)
    yield put({
      type: supplierActions.SET_STATE,
      payload: {
        list: list.filter(supplier => supplier.id !== id),
      },
    })
  }
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      loadingDetails: false,
    },
  })
}

export function* RESET_DETAILS() {
  yield delay(200)
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      details: {},
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: supplierActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(supplierActions.ADD, ADD),
    takeEvery(supplierActions.UPDATE, UPDATE),
    takeEvery(supplierActions.DELETE, DELETE),
    takeLatest(supplierActions.GET_LIST, GET_LIST),
    takeLatest(supplierActions.GET_EDITABLE, GET_EDITABLE),
    takeLatest(supplierActions.RESET_EDITABLE, RESET_EDITABLE),
    takeLatest(supplierActions.GET_DETAILS, GET_DETAILS),
    takeLatest(supplierActions.RESET_DETAILS, RESET_DETAILS),
    takeLatest(supplierActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
