import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import regionActions from './actions'

const endpoint = '/regions'

export function* ADD_REGION({ payload }) {
  const { region } = payload
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, region)
  if (response) {
    notification.success({
      message: 'Region Saved',
      description: 'New region saved successfully!',
    })
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_REGION({ payload }) {
  const { id, region } = payload
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, region)
  if (response) {
    notification.success({
      message: 'Region Updated',
      description: 'Region updated successfully!',
    })
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_REGION({ payload }) {
  const { id } = payload
  const { regionList } = yield select(state => state.region)
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      regionList: regionList.filter(region => region.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        totalDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Region Removed',
      description: 'Region removed successfully!',
    })
  } else {
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        regionList,
      },
    })
  }
}

export function* GET_REGION_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      loadingRegionList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        regionList: response.data.regions,
        totalDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      loadingRegionList: false,
    },
  })
}

export function* GET_EDITABLE_REGION({ payload }) {
  const { id } = payload
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      loadingEditableRegion: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        editableRegion: response.data.region,
      },
    })
  } else if (response && response.code === 404) {
    const { regionList } = yield select(state => state.region)
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        regionList: regionList.filter(region => region.id !== id),
      },
    })
  }
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      loadingEditableRegion: false,
    },
  })
}

export function* RESET_EDITABLE_REGION() {
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      editableRegion: {},
    },
  })
}

export function* GET_REGION_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      loadingRegionDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        regionDetails: response.data.region,
      },
    })
  } else if (response && response.code === 404) {
    const { regionList } = yield select(state => state.region)
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        regionList: regionList.filter(region => region.id !== id),
      },
    })
  }
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      loadingRegionDetails: false,
    },
  })
}

export function* RESET_REGION_DETAILS() {
  yield delay(200)
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      regionDetails: {},
    },
  })
}

export function* GET_REGIONS_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      regionsLookup: [],
      loadingRegionsLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: regionActions.SET_STATE,
      payload: {
        regionsLookup: response.data.regions,
      },
    })
  }
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      loadingRegionsLookup: false,
    },
  })
}

export function* SET_SELECTED_REGION_LOOKUP({ payload }) {
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      selectedRegionLookup: payload.selectedRegionLookup,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: regionActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(regionActions.ADD_REGION, ADD_REGION),
    takeEvery(regionActions.UPDATE_REGION, UPDATE_REGION),
    takeEvery(regionActions.DELETE_REGION, DELETE_REGION),
    takeLatest(regionActions.GET_REGION_LIST, GET_REGION_LIST),
    takeLatest(regionActions.GET_EDITABLE_REGION, GET_EDITABLE_REGION),
    takeLatest(regionActions.RESET_EDITABLE_REGION, RESET_EDITABLE_REGION),
    takeLatest(regionActions.GET_REGION_DETAILS, GET_REGION_DETAILS),
    takeLatest(regionActions.RESET_REGION_DETAILS, RESET_REGION_DETAILS),
    takeLatest(regionActions.GET_REGIONS_LOOKUP, GET_REGIONS_LOOKUP),
    takeLatest(regionActions.SET_SELECTED_REGION_LOOKUP, SET_SELECTED_REGION_LOOKUP),
    takeLatest(regionActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
