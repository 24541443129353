import sectorActions from './actions'

const initialState = {
  sectorList: [],
  loadingSectorList: false,
  editableSector: {},
  loadingEditableSector: false,
  sectorDetails: {},
  loadingSectorDetails: false,
  sectorsLookup: [],
  loadingSectorsLookup: false,
  selectedSectorLookup: {},
  totalDataCount: 0,
  savingStatus: '',
}

export default function sectorReducer(state = initialState, action) {
  switch (action.type) {
    case sectorActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
