const coaActions = {
  SET_STATE: 'coa/SET_STATE',
  GET_COA_DATA: 'coa/GET_COA_DATA',

  ADD_GROUP: 'coa/ADD_GROUP',
  UPDATE_GROUP: 'coa/UPDATE_GROUP',
  DELETE_GROUP: 'coa/DELETE_GROUP',
  GET_GROUP_LIST: 'coa/GET_GROUP_LIST',
  GET_GROUP_DETAILS: 'coa/GET_GROUP_DETAILS',
  RESET_GROUP_DETAILS: 'coa/RESET_GROUP_DETAILS',
  GET_EDITABLE_GROUP: 'coa/GET_EDITABLE_GROUP',
  RESET_EDITABLE_GROUP: 'coa/RESET_EDITABLE_GROUP',

  ADD_SUBGROUP: 'coa/ADD_SUBGROUP',
  UPDATE_SUBGROUP: 'coa/UPDATE_SUBGROUP',
  DELETE_SUBGROUP: 'coa/DELETE_SUBGROUP',
  GET_SUBGROUP_LIST: 'coa/GET_SUBGROUP_LIST',
  GET_SUBGROUP_DETAILS: 'coa/GET_SUBGROUP_DETAILS',
  RESET_SUBGROUP_DETAILS: 'coa/RESET_SUBGROUP_DETAILS',
  GET_EDITABLE_SUBGROUP: 'coa/GET_EDITABLE_SUBGROUP',
  RESET_EDITABLE_SUBGROUP: 'coa/RESET_EDITABLE_SUBGROUP',

  ADD_ACCOUNT: 'coa/ADD_ACCOUNT',
  UPDATE_ACCOUNT: 'coa/UPDATE_ACCOUNT',
  DELETE_ACCOUNT: 'coa/DELETE_ACCOUNT',
  GET_ACCOUNT_LIST: 'coa/GET_ACCOUNT_LIST',
  GET_ACCOUNT_DETAILS: 'coa/GET_ACCOUNT_DETAILS',
  RESET_ACCOUNT_DETAILS: 'coa/RESET_ACCOUNT_DETAILS',
  GET_EDITABLE_ACCOUNT: 'coa/GET_EDITABLE_ACCOUNT',
  RESET_EDITABLE_ACCOUNT: 'coa/RESET_EDITABLE_ACCOUNT',

  GET_NOA_LOOKUP: 'coa/GET_NOA_LOOKUP',
  SET_SELECTED_NOA_LOOKUP: 'coa/SET_SELECTED_NOA_LOOKUP',

  GET_GROUPS_LOOKUP: 'coa/GET_GROUPS_LOOKUP',
  RESET_GROUPS_LOOKUP: 'coa/RESET_GROUPS_LOOKUP',
  SET_SELECTED_GROUP_LOOKUP: 'coa/SET_SELECTED_GROUP_LOOKUP',

  GET_SUBGROUPS_LOOKUP: 'coa/GET_SUBGROUPS_LOOKUP',
  RESET_SUBGROUPS_LOOKUP: 'coa/RESET_SUBGROUPS_LOOKUP',
  SET_SELECTED_SUBGROUP_LOOKUP: 'coa/SET_SELECTED_SUBGROUP_LOOKUP',

  RESET_SAVING_STATUS: 'coa/RESET_SAVING_STATUS',
}

export default coaActions
