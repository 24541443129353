import { all, call, delay, select, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import bankActions from './actions'

const endpoint = '/banks'

export function* ADD_BANK_ACCOUNT({ payload }) {
  const { bankAccount } = payload
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, bankAccount)
  if (response) {
    notification.success({
      message: 'Bank Account Saved',
      description: 'New bank account saved successfully!',
    })
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_BANK_ACCOUNT({ payload }) {
  const { id, bankAccount } = payload
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, bankAccount)
  if (response) {
    notification.success({
      message: 'Bank Account Updated',
      description: 'Bank account updated successfully!',
    })
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_BANK_ACCOUNT({ payload }) {
  const { id } = payload
  const { bankAccountList } = yield select(state => state.bank)
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      bankAccountList: bankAccountList.filter(acc => acc.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        totalBankAccountsDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Bank Account Removed',
      description: 'Bank account removed successfully!',
    })
  } else {
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        bankAccountList,
      },
    })
  }
}

export function* GET_BANK_ACCOUNT_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      loadingBankAccountList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        bankAccountList: response.data.bank_accounts,
        totalBankAccountsDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      loadingBankAccountList: false,
    },
  })
}

export function* GET_EDITABLE_BANK_ACCOUNT({ payload }) {
  const { id } = payload
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      loadingEditableBankAccount: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        editableBankAccount: response.data.bank_account,
      },
    })
  } else if (response && response.code === 404) {
    const { bankAccountList } = yield select(state => state.bank)
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        bankAccountList: bankAccountList.filter(acc => acc.id !== id),
      },
    })
  }
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      loadingEditableBankAccount: false,
    },
  })
}

export function* RESET_EDITABLE_BANK_ACCOUNT() {
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      editableBankAccount: {},
    },
  })
}

export function* GET_BANK_ACCOUNT_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      loadingBankAccountDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        bankAccountDetails: response.data.bank_account,
      },
    })
  } else if (response && response.code === 404) {
    const { bankAccountList } = yield select(state => state.bank)
    yield put({
      type: bankActions.SET_STATE,
      payload: {
        bankAccountList: bankAccountList.filter(acc => acc.id !== id),
      },
    })
  }
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      loadingBankAccountDetails: false,
    },
  })
}

export function* RESET_BANK_ACCOUNT_DETAILS() {
  yield delay(200)
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      bankAccountDetails: {},
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: bankActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(bankActions.ADD_BANK_ACCOUNT, ADD_BANK_ACCOUNT),
    takeEvery(bankActions.UPDATE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT),
    takeEvery(bankActions.DELETE_BANK_ACCOUNT, DELETE_BANK_ACCOUNT),
    takeLatest(bankActions.GET_BANK_ACCOUNT_LIST, GET_BANK_ACCOUNT_LIST),
    takeLatest(bankActions.GET_BANK_ACCOUNT_DETAILS, GET_BANK_ACCOUNT_DETAILS),
    takeLatest(bankActions.RESET_BANK_ACCOUNT_DETAILS, RESET_BANK_ACCOUNT_DETAILS),
    takeLatest(bankActions.GET_EDITABLE_BANK_ACCOUNT, GET_EDITABLE_BANK_ACCOUNT),
    takeLatest(bankActions.RESET_EDITABLE_BANK_ACCOUNT, RESET_EDITABLE_BANK_ACCOUNT),

    takeLatest(bankActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
