import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authUser from './authUser/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import user from './user/reducers'
import accountAccessGroup from './registration/account-registration/account-access-group/reducers'
import chartOfAccounts from './registration/chart-of-accounts/reducers'
import bank from './registration/account-registration/bank-account/reducers'
import region from './party-registration/region/reducers'
import area from './party-registration/area/reducers'
import sector from './party-registration/sector/reducers'
import client from './party-registration/client/reducers'
import supplier from './party-registration/supplier/reducers'
import mainUnit from './registration/product-registration/main-unit/reducers'
import warehouse from './registration/general-registration/warehouse/reducers'
import service from './registration/general-registration/service/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    authUser,
    menu,
    settings,
    user,
    accountAccessGroup,
    chartOfAccounts,
    bank,
    region,
    area,
    sector,
    client,
    supplier,
    mainUnit,
    warehouse,
    service,
  })
