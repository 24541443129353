import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as authUserJwtService from 'services/authUserJwt'
import authUserActions from './actions'

const mapAuthProviders = {
  jwt: {
    login: authUserJwtService.login,
    loadMe: authUserJwtService.loadMe,
    logout: authUserJwtService.logout,
    register: authUserJwtService.register,
    forgotPassword: authUserJwtService.forgotPassword,
    resetPassword: authUserJwtService.resetPassword,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].login, email, password)
  if (success) {
    yield put({
      type: authUserActions.LOAD_ME,
    })
    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
  if (!success) {
    yield put({
      type: authUserActions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_ME() {
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].loadMe)
  if (response) {
    const { id, email, name, avatar, role } = response.me
    yield put({
      type: authUserActions.SET_STATE,
      payload: {
        id,
        name,
        email,
        avatar,
        role,
        authorized: true,
      },
    })
  }
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: authUserActions.LOAD_ME,
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: authUserActions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* FORGOT_PASSWORD({ payload }) {
  const { email } = payload
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].forgotPassword, email)
  if (success) {
    notification.success({
      message: 'Password Reset Email Sent',
      description: 'Please check your inbox to reset password.',
    })
  }
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* RESET_PASSWORD({ payload }) {
  const { password, confirmPassword } = payload
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(
    mapAuthProviders[authProvider].resetPassword,
    password,
    confirmPassword,
  )
  if (success) {
    yield history.replace('/auth/login')
    notification.success({
      message: 'Password Reset',
      description: 'Your password reset successfully',
    })
  }
  yield put({
    type: authUserActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(authUserActions.LOGIN, LOGIN),
    takeEvery(authUserActions.LOAD_ME, LOAD_ME),
    takeEvery(authUserActions.LOGOUT, LOGOUT),
    takeEvery(authUserActions.REGISTER, REGISTER),
    takeEvery(authUserActions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(authUserActions.RESET_PASSWORD, RESET_PASSWORD),
    LOAD_ME(), // run once on app load to check user auth
  ])
}
