import { all, call, delay, select, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import sectorActions from './actions'

const endpoint = '/sectors'

export function* ADD_SECTOR({ payload }) {
  const { sector } = payload
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, sector)
  if (response) {
    notification.success({
      message: 'Sector Saved',
      description: 'New sector saved successfully!',
    })
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_SECTOR({ payload }) {
  const { id, sector } = payload
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, sector)
  if (response) {
    notification.success({
      message: 'Sector Updated',
      description: 'Sector updated successfully!',
    })
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_SECTOR({ payload }) {
  const { id } = payload
  const { sectorList } = yield select(state => state.sector)
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      sectorList: sectorList.filter(sector => sector.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        totalDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Sector Removed',
      description: 'Sector removed successfully!',
    })
  } else {
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        sectorList,
      },
    })
  }
}

export function* GET_SECTOR_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      loadingSectorList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        sectorList: response.data.sectors,
        totalDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      loadingSectorList: false,
    },
  })
}

export function* GET_EDITABLE_SECTOR({ payload }) {
  const { id } = payload
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      loadingEditableSector: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        editableSector: response.data.sector,
      },
    })
  } else if (response && response.code === 404) {
    const { sectorList } = yield select(state => state.sector)
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        sectorList: sectorList.filter(sector => sector.id !== id),
      },
    })
  }
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      loadingEditableSector: false,
    },
  })
}

export function* RESET_EDITABLE_SECTOR() {
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      editableSector: {},
    },
  })
}

export function* GET_SECTOR_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      loadingSectorDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        sectorDetails: response.data.sector,
      },
    })
  } else if (response && response.code === 404) {
    const { sectorList } = yield select(state => state.sector)
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        sectorList: sectorList.filter(sector => sector.id !== id),
      },
    })
  }
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      loadingSectorDetails: false,
    },
  })
}

export function* RESET_SECTOR_DETAILS() {
  yield delay(200)
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      sectorDetails: {},
    },
  })
}

export function* GET_SECTORS_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      sectorsLookup: [],
      loadingSectorsLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: sectorActions.SET_STATE,
      payload: {
        sectorsLookup: response.data.sectors,
      },
    })
  }
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      loadingSectorsLookup: false,
    },
  })
}

export function* SET_SELECTED_SECTOR_LOOKUP({ payload }) {
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      selectedSectorLookup: payload.selectedSectorLookup,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: sectorActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(sectorActions.ADD_SECTOR, ADD_SECTOR),
    takeEvery(sectorActions.UPDATE_SECTOR, UPDATE_SECTOR),
    takeEvery(sectorActions.DELETE_SECTOR, DELETE_SECTOR),
    takeLatest(sectorActions.GET_SECTOR_LIST, GET_SECTOR_LIST),
    takeLatest(sectorActions.GET_EDITABLE_SECTOR, GET_EDITABLE_SECTOR),
    takeLatest(sectorActions.RESET_EDITABLE_SECTOR, RESET_EDITABLE_SECTOR),
    takeLatest(sectorActions.GET_SECTOR_DETAILS, GET_SECTOR_DETAILS),
    takeLatest(sectorActions.RESET_SECTOR_DETAILS, RESET_SECTOR_DETAILS),
    takeLatest(sectorActions.GET_SECTORS_LOOKUP, GET_SECTORS_LOOKUP),
    takeLatest(sectorActions.SET_SELECTED_SECTOR_LOOKUP, SET_SELECTED_SECTOR_LOOKUP),
    takeLatest(sectorActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
