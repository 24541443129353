import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
  },

  // Registration
  // Account Registration
  {
    path: '/account-registration/account-access-group',
    Component: lazy(() => import('pages/registration/account-registration/account-access-group')),
    exact: true,
  },
  {
    path: '/account-registration/account-access-group-list',
    Component: lazy(() =>
      import('pages/registration/account-registration/account-access-group-list'),
    ),
    exact: true,
  },
  {
    path: '/account-registration/account',
    Component: lazy(() => import('pages/registration/account-registration/account')),
    exact: true,
  },
  {
    path: '/account-registration/account-list',
    Component: lazy(() => import('pages/registration/account-registration/account-list')),
    exact: true,
  },
  {
    path: '/account-registration/bank-account',
    Component: lazy(() => import('pages/registration/account-registration/bank-account')),
    exact: true,
  },
  {
    path: '/account-registration/bank-account-list',
    Component: lazy(() => import('pages/registration/account-registration/bank-account-list')),
    exact: true,
  },

  // Chart of Accounts
  {
    path: '/chart-of-accounts/chart-of-accounts',
    Component: lazy(() => import('pages/registration/chart-of-accounts/chart-of-accounts')),
    exact: true,
  },
  {
    path: '/chart-of-accounts/group',
    Component: lazy(() => import('pages/registration/chart-of-accounts/group')),
    exact: true,
  },
  {
    path: '/chart-of-accounts/group-list',
    Component: lazy(() => import('pages/registration/chart-of-accounts/group-list')),
    exact: true,
  },
  {
    path: '/chart-of-accounts/sub-group',
    Component: lazy(() => import('pages/registration/chart-of-accounts/sub-group')),
    exact: true,
  },
  {
    path: '/chart-of-accounts/sub-group-list',
    Component: lazy(() => import('pages/registration/chart-of-accounts/sub-group-list')),
    exact: true,
  },

  // Party Registration
  {
    path: '/party-registration/region',
    Component: lazy(() => import('pages/registration/party-registration/region')),
    exact: true,
  },
  {
    path: '/party-registration/region-list',
    Component: lazy(() => import('pages/registration/party-registration/region-list')),
    exact: true,
  },
  {
    path: '/party-registration/area',
    Component: lazy(() => import('pages/registration/party-registration/area')),
    exact: true,
  },
  {
    path: '/party-registration/area-list',
    Component: lazy(() => import('pages/registration/party-registration/area-list')),
    exact: true,
  },
  {
    path: '/party-registration/sector',
    Component: lazy(() => import('pages/registration/party-registration/sector')),
    exact: true,
  },
  {
    path: '/party-registration/sector-list',
    Component: lazy(() => import('pages/registration/party-registration/sector-list')),
    exact: true,
  },
  {
    path: '/party-registration/client',
    Component: lazy(() => import('pages/registration/party-registration/client')),
    exact: true,
  },
  {
    path: '/party-registration/client-list',
    Component: lazy(() => import('pages/registration/party-registration/client-list')),
    exact: true,
  },
  {
    path: '/party-registration/supplier',
    Component: lazy(() => import('pages/registration/party-registration/supplier')),
    exact: true,
  },
  {
    path: '/party-registration/supplier-list',
    Component: lazy(() => import('pages/registration/party-registration/supplier-list')),
    exact: true,
  },

  // Product Registration
  {
    path: '/product-registration/main-unit',
    Component: lazy(() => import('pages/registration/product-registration/main-unit')),
    exact: true,
  },
  {
    path: '/product-registration/main-unit-list',
    Component: lazy(() => import('pages/registration/product-registration/main-unit-list')),
    exact: true,
  },
  {
    path: '/product-registration/unit',
    Component: lazy(() => import('pages/registration/product-registration/unit')),
    exact: true,
  },
  {
    path: '/product-registration/unit-list',
    Component: lazy(() => import('pages/registration/product-registration/unit-list')),
    exact: true,
  },

  // General Registration
  {
    path: '/general-registration/warehouse',
    Component: lazy(() => import('pages/registration/general-registration/warehouse')),
    exact: true,
  },
  {
    path: '/general-registration/warehouse-list',
    Component: lazy(() => import('pages/registration/general-registration/warehouse-list')),
    exact: true,
  },
  {
    path: '/general-registration/service',
    Component: lazy(() => import('pages/registration/general-registration/service')),
    exact: true,
  },
  {
    path: '/general-registration/service-list',
    Component: lazy(() => import('pages/registration/general-registration/service-list')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  // {
  //   path: '/auth/register',
  //   Component: lazy(() => import('pages/auth/register')),
  //   exact: true,
  // },
  // {
  //   path: '/auth/lockscreen',
  //   Component: lazy(() => import('pages/auth/lockscreen')),
  //   exact: true,
  // },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback="Loading...">
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
