import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { BackTop } from 'antd'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ authUser }) => ({ authUser })
let previousPath = ''

const Layout = ({ authUser, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = authUser.authorized
  const isUserLoading = authUser.loading
  const isAuthLayout = getLayout() === 'auth'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return <Redirect to="/auth/login" />
    }
    if (
      isUserAuthorized &&
      (pathname.toLowerCase() === '/auth/login' ||
        pathname.toLowerCase() === '/auth/forgot-password' ||
        pathname.toLowerCase() === '/auth/reset-password')
    ) {
      return <Redirect to="/dashboard" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Fire Profit | %s" title="" />
      <BackTop>
        <i className="ant-back-top-inner fa fa-angle-double-up" />
      </BackTop>
      <style>
        {`
            .ant-back-top {
              bottom: 100px;
              z-index: 10000;
            }
            .ant-back-top-inner {
              height: 40px;
              width: 40px;
              line-height: 40px;
              border-radius: 4px;
              background-color: #1088e9;
              color: #fff;
              text-align: center;
              font-size: 28px;
            }
          `}
      </style>

      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
