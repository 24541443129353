import coaActions from './actions'

const initialState = {
  coaData: [],
  loadingCoaData: false,

  groupList: [],
  loadingGroupList: false,
  groupDetails: {},
  loadingGroupDetails: false,
  editableGroup: {},
  loadingEditableGroup: false,
  totalGroupsDataCount: 0,

  subGroupList: [],
  loadingSubGroupList: false,
  subGroupDetails: {},
  loadingSubGroupDetails: false,
  editableSubGroup: {},
  loadingEditableSubGroup: false,
  totalSubGroupsDataCount: 0,

  accountList: [],
  loadingAccountList: false,
  accountDetails: {},
  loadingAccountDetails: false,
  editableAccount: {},
  loadingEditableAccount: false,
  totalAccountsDataCount: 0,

  noaLookup: [],
  loadingNoaLookup: false,
  selectedNoaLookup: {},

  groupsLookup: [],
  loadingGroupsLookup: false,
  selectedGroupLookup: {},

  subGroupsLookup: [],
  loadingSubGroupsLookup: false,
  selectedSubGroupLookup: {},

  savingStatus: '',
}

export default function coaReducer(state = initialState, action) {
  switch (action.type) {
    case coaActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
