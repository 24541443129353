import { all, call, delay, select, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import coaActions from './actions'

const endpoint = '/chart_of_accounts'
const accEndpoint = '/accounts'

export function* GET_COA_DATA() {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingCoaData: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        coaData: response.data.chart_of_accounts,
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingCoaData: false,
    },
  })
}

export function* ADD_GROUP({ payload }) {
  const { group } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, group)
  if (response) {
    notification.success({
      message: 'Group Saved',
      description: 'New group saved successfully!',
    })
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_GROUP({ payload }) {
  const { id, group } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, group)
  if (response) {
    notification.success({
      message: 'Group Updated',
      description: 'Group updated successfully!',
    })
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_GROUP({ payload }) {
  const { id } = payload
  const { groupList } = yield select(state => state.chartOfAccounts)
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      groupList: groupList.filter(group => group.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        totalGroupsDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Group Removed',
      description: 'Group removed successfully!',
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        groupList,
      },
    })
  }
}

export function* GET_GROUP_LIST({ payload }) {
  const queryParams = { ...payload, level: 2 }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingGroupList: true,
    },
  })
  const response = yield call(registrationService.getItems, '/chart_of_accounts/list', queryParams)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        groupList: response.data.chart_of_accounts,
        totalGroupsDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingGroupList: false,
    },
  })
}

export function* GET_EDITABLE_GROUP({ payload }) {
  const { id } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingEditableGroup: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        editableGroup: response.data.chart_of_account,
      },
    })
  } else if (response && response.code === 404) {
    const { groupList } = yield select(state => state.chartOfAccounts)
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        groupList: groupList.filter(group => group.id !== id),
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingEditableGroup: false,
    },
  })
}

export function* RESET_EDITABLE_GROUP() {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      editableGroup: {},
    },
  })
}

export function* GET_GROUP_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingGroupDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        groupDetails: response.data.chart_of_account,
      },
    })
  } else if (response && response.code === 404) {
    const { groupList } = yield select(state => state.chartOfAccounts)
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        groupList: groupList.filter(group => group.id !== id),
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingGroupDetails: false,
    },
  })
}

export function* RESET_GROUP_DETAILS() {
  yield delay(200)
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      groupDetails: {},
    },
  })
}

export function* ADD_SUBGROUP({ payload }) {
  const { subGroup } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, subGroup)
  if (response) {
    notification.success({
      message: 'Sub-Group Saved',
      description: 'New sub-group saved successfully!',
    })
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_SUBGROUP({ payload }) {
  const { id, subGroup } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, subGroup)
  if (response) {
    notification.success({
      message: 'Sub-Group Updated',
      description: 'Sub-Group updated successfully!',
    })
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_SUBGROUP({ payload }) {
  const { id } = payload
  const { subGroupList } = yield select(state => state.chartOfAccounts)
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      subGroupList: subGroupList.filter(subGroup => subGroup.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        totalSubGroupsDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Sub-Group Removed',
      description: 'Sub-Group removed successfully!',
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        subGroupList,
      },
    })
  }
}

export function* GET_SUBGROUP_LIST({ payload }) {
  const queryParams = { ...payload, level: 3 }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingSubGroupList: true,
    },
  })
  const response = yield call(registrationService.getItems, '/chart_of_accounts/list', queryParams)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        subGroupList: response.data.chart_of_accounts,
        totalSubGroupsDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingSubGroupList: false,
    },
  })
}

export function* GET_EDITABLE_SUBGROUP({ payload }) {
  const { id } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingEditableSubGroup: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        editableSubGroup: response.data.chart_of_account,
      },
    })
  } else if (response && response.code === 404) {
    const { subGroupList } = yield select(state => state.chartOfAccounts)
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        subGroupList: subGroupList.filter(subGroup => subGroup.id !== id),
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingEditableSubGroup: false,
    },
  })
}

export function* RESET_EDITABLE_SUBGROUP() {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      editableSubGroup: {},
    },
  })
}

export function* GET_SUBGROUP_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingSubGroupDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        subGroupDetails: response.data.chart_of_account,
      },
    })
  } else if (response && response.code === 404) {
    const { subGroupList } = yield select(state => state.chartOfAccounts)
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        subGroupList: subGroupList.filter(subGroup => subGroup.id !== id),
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingSubGroupDetails: false,
    },
  })
}

export function* RESET_SUBGROUP_DETAILS() {
  yield delay(200)
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      subGroupDetails: {},
    },
  })
}

export function* ADD_ACCOUNT({ payload }) {
  const { account } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, accEndpoint, account)
  if (response) {
    notification.success({
      message: 'Account Saved',
      description: 'New account saved successfully!',
    })
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_ACCOUNT({ payload }) {
  const { id, account } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, accEndpoint, id, account)
  if (response) {
    notification.success({
      message: 'Account Updated',
      description: 'Account updated successfully!',
    })
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_ACCOUNT({ payload }) {
  const { id } = payload
  const { accountList } = yield select(state => state.chartOfAccounts)
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      accountList: accountList.filter(acc => acc.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, accEndpoint, id)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        totalAccountsDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Account Removed',
      description: 'Account removed successfully!',
    })
  } else {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        accountList,
      },
    })
  }
}

export function* GET_ACCOUNT_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingAccountList: true,
    },
  })
  const response = yield call(registrationService.getItems, accEndpoint, queryParams)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        accountList: response.data.accounts,
        totalAccountsDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingAccountList: false,
    },
  })
}

export function* GET_EDITABLE_ACCOUNT({ payload }) {
  const { id } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingEditableAccount: true,
    },
  })
  const response = yield call(registrationService.getItemById, accEndpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        editableAccount: response.data.account,
      },
    })
  } else if (response && response.code === 404) {
    const { accountList } = yield select(state => state.chartOfAccounts)
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        accountList: accountList.filter(acc => acc.id !== id),
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingEditableAccount: false,
    },
  })
}

export function* RESET_EDITABLE_ACCOUNT() {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      editableAccount: {},
    },
  })
}

export function* GET_ACCOUNT_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingAccountDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, accEndpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        accountDetails: response.data.account,
      },
    })
  } else if (response && response.code === 404) {
    const { accountList } = yield select(state => state.chartOfAccounts)
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        accountList: accountList.filter(acc => acc.id !== id),
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingAccountDetails: false,
    },
  })
}

export function* RESET_ACCOUNT_DETAILS() {
  yield delay(200)
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      accountDetails: {},
    },
  })
}

export function* GET_NOA_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      noaLookup: [],
      loadingNoaLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        noaLookup: response.data.chart_of_accounts,
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingNoaLookup: false,
    },
  })
}

export function* SET_SELECTED_NOA_LOOKUP({ payload }) {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      selectedNoaLookup: payload.selectedNoaLookup,
    },
  })
}

export function* GET_GROUPS_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      groupsLookup: [],
      loadingGroupsLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        groupsLookup: response.data.chart_of_accounts,
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingGroupsLookup: false,
    },
  })
}

export function* RESET_GROUPS_LOOKUP() {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      groupsLookup: [],
    },
  })
}

export function* SET_SELECTED_GROUP_LOOKUP({ payload }) {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      selectedGroupLookup: payload.selectedGroupLookup,
    },
  })
}

export function* GET_SUBGROUPS_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      subGroupsLookup: [],
      loadingSubGroupsLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: coaActions.SET_STATE,
      payload: {
        subGroupsLookup: response.data.chart_of_accounts,
      },
    })
  }
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      loadingSubGroupsLookup: false,
    },
  })
}

export function* RESET_SUBGROUPS_LOOKUP() {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      subGroupsLookup: [],
    },
  })
}

export function* SET_SELECTED_SUBGROUP_LOOKUP({ payload }) {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      selectedSubGroupLookup: payload.selectedSubGroupLookup,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: coaActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(coaActions.GET_COA_DATA, GET_COA_DATA),

    takeEvery(coaActions.ADD_GROUP, ADD_GROUP),
    takeEvery(coaActions.UPDATE_GROUP, UPDATE_GROUP),
    takeEvery(coaActions.DELETE_GROUP, DELETE_GROUP),
    takeLatest(coaActions.GET_GROUP_LIST, GET_GROUP_LIST),
    takeLatest(coaActions.GET_GROUP_DETAILS, GET_GROUP_DETAILS),
    takeLatest(coaActions.RESET_GROUP_DETAILS, RESET_GROUP_DETAILS),
    takeLatest(coaActions.GET_EDITABLE_GROUP, GET_EDITABLE_GROUP),
    takeLatest(coaActions.RESET_EDITABLE_GROUP, RESET_EDITABLE_GROUP),

    takeEvery(coaActions.ADD_SUBGROUP, ADD_SUBGROUP),
    takeEvery(coaActions.UPDATE_SUBGROUP, UPDATE_SUBGROUP),
    takeEvery(coaActions.DELETE_SUBGROUP, DELETE_SUBGROUP),
    takeLatest(coaActions.GET_SUBGROUP_LIST, GET_SUBGROUP_LIST),
    takeLatest(coaActions.GET_SUBGROUP_DETAILS, GET_SUBGROUP_DETAILS),
    takeLatest(coaActions.RESET_SUBGROUP_DETAILS, RESET_SUBGROUP_DETAILS),
    takeLatest(coaActions.GET_EDITABLE_SUBGROUP, GET_EDITABLE_SUBGROUP),
    takeLatest(coaActions.RESET_EDITABLE_SUBGROUP, RESET_EDITABLE_SUBGROUP),

    takeEvery(coaActions.ADD_ACCOUNT, ADD_ACCOUNT),
    takeEvery(coaActions.UPDATE_ACCOUNT, UPDATE_ACCOUNT),
    takeEvery(coaActions.DELETE_ACCOUNT, DELETE_ACCOUNT),
    takeLatest(coaActions.GET_ACCOUNT_LIST, GET_ACCOUNT_LIST),
    takeLatest(coaActions.GET_ACCOUNT_DETAILS, GET_ACCOUNT_DETAILS),
    takeLatest(coaActions.RESET_ACCOUNT_DETAILS, RESET_ACCOUNT_DETAILS),
    takeLatest(coaActions.GET_EDITABLE_ACCOUNT, GET_EDITABLE_ACCOUNT),
    takeLatest(coaActions.RESET_EDITABLE_ACCOUNT, RESET_EDITABLE_ACCOUNT),

    takeLatest(coaActions.GET_NOA_LOOKUP, GET_NOA_LOOKUP),
    takeLatest(coaActions.SET_SELECTED_NOA_LOOKUP, SET_SELECTED_NOA_LOOKUP),

    takeLatest(coaActions.GET_GROUPS_LOOKUP, GET_GROUPS_LOOKUP),
    takeLatest(coaActions.RESET_GROUPS_LOOKUP, RESET_GROUPS_LOOKUP),
    takeLatest(coaActions.SET_SELECTED_GROUP_LOOKUP, SET_SELECTED_GROUP_LOOKUP),

    takeLatest(coaActions.GET_SUBGROUPS_LOOKUP, GET_SUBGROUPS_LOOKUP),
    takeLatest(coaActions.RESET_SUBGROUPS_LOOKUP, RESET_SUBGROUPS_LOOKUP),
    takeLatest(coaActions.SET_SELECTED_SUBGROUP_LOOKUP, SET_SELECTED_SUBGROUP_LOOKUP),

    takeLatest(coaActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
