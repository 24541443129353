import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import mainUnitActions from './actions'

const endpoint = '/main_units'

export function* ADD({ payload }) {
  const { mainUnit } = payload
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, mainUnit)
  if (response) {
    notification.success({
      message: 'Main Unit Saved',
      description: 'New main unit saved successfully!',
    })
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, mainUnit } = payload
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, mainUnit)
  if (response) {
    notification.success({
      message: 'Main Unit Updated',
      description: 'Main unit updated successfully!',
    })
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  const { list } = yield select(state => state.mainUnit)
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      list: list.filter(unit => unit.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        totalDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Main Unit Removed',
      description: 'Main unit removed successfully!',
    })
  } else {
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        list,
      },
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      loadingList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        list: response.data.main_units,
        totalDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      loadingList: false,
    },
  })
}

export function* GET_EDITABLE({ payload }) {
  const { id } = payload
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      loadingEditable: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        editable: response.data.main_unit,
      },
    })
  } else if (response && response.code === 404) {
    const { list } = yield select(state => state.mainUnit)
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        list: list.filter(unit => unit.id !== id),
      },
    })
  }
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      loadingEditable: false,
    },
  })
}

export function* RESET_EDITABLE() {
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      editable: {},
    },
  })
}

export function* GET_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      loadingDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        details: response.data.main_unit,
      },
    })
  } else if (response && response.code === 404) {
    const { list } = yield select(state => state.mainUnit)
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        list: list.filter(unit => unit.id !== id),
      },
    })
  }
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      loadingDetails: false,
    },
  })
}

export function* RESET_DETAILS() {
  yield delay(200)
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      details: {},
    },
  })
}

export function* GET_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      lookup: [],
      loadingLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: mainUnitActions.SET_STATE,
      payload: {
        lookup: response.data.main_units,
      },
    })
  }
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      loadingLookup: false,
    },
  })
}

export function* SET_SELECTED_LOOKUP({ payload }) {
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      selectedLookup: payload.selectedLookup,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: mainUnitActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(mainUnitActions.ADD, ADD),
    takeEvery(mainUnitActions.UPDATE, UPDATE),
    takeEvery(mainUnitActions.DELETE, DELETE),
    takeLatest(mainUnitActions.GET_LIST, GET_LIST),
    takeLatest(mainUnitActions.GET_EDITABLE, GET_EDITABLE),
    takeLatest(mainUnitActions.RESET_EDITABLE, RESET_EDITABLE),
    takeLatest(mainUnitActions.GET_DETAILS, GET_DETAILS),
    takeLatest(mainUnitActions.RESET_DETAILS, RESET_DETAILS),
    takeLatest(mainUnitActions.GET_LOOKUP, GET_LOOKUP),
    takeLatest(mainUnitActions.SET_SELECTED_LOOKUP, SET_SELECTED_LOOKUP),
    takeLatest(mainUnitActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
