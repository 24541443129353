import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as registrationService from 'services/registration'
import serviceActions from './actions'

const endpoint = '/services'

export function* ADD({ payload }) {
  const { service } = payload
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.addItem, endpoint, service)
  if (response) {
    notification.success({
      message: 'Service Saved',
      description: 'New service saved successfully!',
    })
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, service } = payload
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(registrationService.updateItem, endpoint, id, service)
  if (response) {
    notification.success({
      message: 'Service Updated',
      description: 'Service updated successfully!',
    })
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        savingStatus: 'successful',
      },
    })
  } else {
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  const { list } = yield select(state => state.service)
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      list: list.filter(service => service.id !== id),
    },
  })
  const response = yield call(registrationService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        totalDataCount: response.data.total_records,
      },
    })
    notification.success({
      message: 'Service Removed',
      description: 'Service removed successfully!',
    })
  } else {
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        list,
      },
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      loadingList: true,
    },
  })
  const response = yield call(registrationService.getItems, endpoint, queryParams)
  if (response) {
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        list: response.data.services,
        totalDataCount: response.data.total_records,
      },
    })
  }
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      loadingList: false,
    },
  })
}

export function* GET_EDITABLE({ payload }) {
  const { id } = payload
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      loadingEditable: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        editable: response.data.service,
      },
    })
  } else if (response && response.code === 404) {
    const { list } = yield select(state => state.service)
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        list: list.filter(service => service.id !== id),
      },
    })
  }
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      loadingEditable: false,
    },
  })
}

export function* RESET_EDITABLE() {
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      editable: {},
    },
  })
}

export function* GET_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      loadingDetails: true,
    },
  })
  const response = yield call(registrationService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        details: response.data.service,
      },
    })
  } else if (response && response.code === 404) {
    const { list } = yield select(state => state.service)
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        list: list.filter(service => service.id !== id),
      },
    })
  }
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      loadingDetails: false,
    },
  })
}

export function* RESET_DETAILS() {
  yield delay(200)
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      details: {},
    },
  })
}

export function* GET_LOOKUP({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      lookup: [],
      loadingLookup: true,
    },
  })
  const response = yield call(registrationService.getItemsLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: serviceActions.SET_STATE,
      payload: {
        lookup: response.data.services,
      },
    })
  }
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      loadingLookup: false,
    },
  })
}

export function* SET_SELECTED_LOOKUP({ payload }) {
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      selectedLookup: payload.selectedLookup,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: serviceActions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(serviceActions.ADD, ADD),
    takeEvery(serviceActions.UPDATE, UPDATE),
    takeEvery(serviceActions.DELETE, DELETE),
    takeLatest(serviceActions.GET_LIST, GET_LIST),
    takeLatest(serviceActions.GET_EDITABLE, GET_EDITABLE),
    takeLatest(serviceActions.RESET_EDITABLE, RESET_EDITABLE),
    takeLatest(serviceActions.GET_DETAILS, GET_DETAILS),
    takeLatest(serviceActions.RESET_DETAILS, RESET_DETAILS),
    takeLatest(serviceActions.GET_LOOKUP, GET_LOOKUP),
    takeLatest(serviceActions.SET_SELECTED_LOOKUP, SET_SELECTED_LOOKUP),
    takeLatest(serviceActions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
