const areaActions = {
  SET_STATE: 'area/SET_STATE',
  ADD_AREA: 'area/ADD_AREA',
  UPDATE_AREA: 'area/UPDATE_AREA',
  DELETE_AREA: 'area/DELETE_AREA',
  GET_AREA_LIST: 'area/GET_AREA_LIST',
  GET_EDITABLE_AREA: 'area/GET_EDITABLE_AREA',
  RESET_EDITABLE_AREA: 'area/RESET_EDITABLE_AREA',
  GET_AREA_DETAILS: 'area/GET_AREA_DETAILS',
  RESET_AREA_DETAILS: 'area/RESET_AREA_DETAILS',
  GET_AREAS_LOOKUP: 'area/GET_AREAS_LOOKUP',
  SET_SELECTED_AREA_LOOKUP: 'area/SET_SELECTED_AREA_LOOKUP',
  RESET_SAVING_STATUS: 'area/RESET_SAVING_STATUS',
}

export default areaActions
