const authUserActions = {
  SET_STATE: 'authUser/SET_STATE',
  LOGIN: 'authUser/LOGIN',
  LOAD_ME: 'authUser/LOAD_ME',
  LOGOUT: 'authUser/LOGOUT',
  REGISTER: 'authUser/REGISTER',
  FORGOT_PASSWORD: 'authUser/FORGOT_PASSWORD',
  RESET_PASSWORD: 'authUser/RESET_PASSWORD',
}

export default authUserActions
