const sectorActions = {
  SET_STATE: 'sector/SET_STATE',
  ADD_SECTOR: 'sector/ADD_SECTOR',
  UPDATE_SECTOR: 'sector/UPDATE_SECTOR',
  DELETE_SECTOR: 'sector/DELETE_SECTOR',
  GET_SECTOR_LIST: 'sector/GET_SECTOR_LIST',
  GET_EDITABLE_SECTOR: 'sector/GET_EDITABLE_SECTOR',
  RESET_EDITABLE_SECTOR: 'sector/RESET_EDITABLE_SECTOR',
  GET_SECTOR_DETAILS: 'sector/GET_SECTOR_DETAILS',
  RESET_SECTOR_DETAILS: 'sector/RESET_SECTOR_DETAILS',
  GET_SECTORS_LOOKUP: 'sector/GET_SECTORS_LOOKUP',
  SET_SELECTED_SECTOR_LOOKUP: 'sector/SET_SELECTED_SECTOR_LOOKUP',
  RESET_SAVING_STATUS: 'sector/RESET_SAVING_STATUS',
}

export default sectorActions
