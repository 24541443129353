export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Home',
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-home',
      url: '/dashboard',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      category: true,
      title: 'Registration',
    },
    {
      title: 'Account Registration',
      key: 'accReg',
      icon: 'fe fe-briefcase',
      children: [
        {
          title: 'Account Access Group',
          key: 'accRegAccountAccessGroup',
          url: '/account-registration/account-access-group',
        },
        {
          title: 'Account Access Group List',
          key: 'accRegAccountAccessGroupList',
          url: '/account-registration/account-access-group-list',
        },
        {
          title: 'Account',
          key: 'accRegAccount',
          url: '/account-registration/account',
        },
        {
          title: 'Account List',
          key: 'accRegAccountList',
          url: '/account-registration/account-list',
        },
        {
          title: 'Bank Account',
          key: 'accRegBankAccount',
          url: '/account-registration/bank-account',
        },
        {
          title: 'Bank Account List',
          key: 'accRegBankAccountList',
          url: '/account-registration/bank-account-list',
        },
      ],
    },
    {
      title: 'Chart of Accounts',
      key: 'chartOfAcc',
      icon: 'fe fe-book-open',
      children: [
        {
          title: 'Chart of Accounts',
          key: 'chartOfAccChartOfAccounts',
          url: '/chart-of-accounts/chart-of-accounts',
        },
        {
          title: 'Group',
          key: 'chartOfAccGroup',
          url: '/chart-of-accounts/group',
        },
        {
          title: 'Group List',
          key: 'chartOfAccGroupList',
          url: '/chart-of-accounts/group-list',
        },
        {
          title: 'Sub-Group',
          key: 'chartOfAccSubGroup',
          url: '/chart-of-accounts/sub-group',
        },
        {
          title: 'Sub-Group List',
          key: 'chartOfAccSubGroupList',
          url: '/chart-of-accounts/sub-group-list',
        },
      ],
    },
    {
      title: 'Party Registration',
      key: 'partyRegistration',
      icon: 'fe fe-hard-drive',
      children: [
        {
          title: 'Region',
          key: 'partyRegistrationRegion',
          url: '/party-registration/region',
        },
        {
          title: 'Region List',
          key: 'partyRegistrationRegionList',
          url: '/party-registration/region-list',
        },
        {
          title: 'Area',
          key: 'partyRegistrationArea',
          url: '/party-registration/area',
        },
        {
          title: 'Area List',
          key: 'partyRegistrationAreaList',
          url: '/party-registration/area-list',
        },
        {
          title: 'Sector',
          key: 'partyRegistrationSector',
          url: '/party-registration/sector',
        },
        {
          title: 'Sector List',
          key: 'partyRegistrationSectorList',
          url: '/party-registration/sector-list',
        },
        {
          title: 'Client',
          key: 'partyRegistrationClient',
          url: '/party-registration/client',
        },
        {
          title: 'Client List',
          key: 'partyRegistrationClientList',
          url: '/party-registration/client-list',
        },
        {
          title: 'Supplier',
          key: 'partyRegistrationSupplier',
          url: '/party-registration/supplier',
        },
        {
          title: 'Supplier List',
          key: 'partyRegistrationSupplierList',
          url: '/party-registration/supplier-list',
        },
      ],
    },
    {
      title: 'Product Registration',
      key: 'productRegistration',
      icon: 'fe fe-package',
      children: [
        {
          title: 'Main Unit',
          key: 'prodRegMainUnit',
          url: '/product-registration/main-unit',
        },
        {
          title: 'Main Unit List',
          key: 'prodRegMainUnitList',
          url: '/product-registration/main-unit-list',
        },
        {
          title: 'Unit',
          key: 'prodRegUnit',
          url: '/product-registration/unit',
        },
        {
          title: 'Unit List',
          key: 'prodRegUnitList',
          url: '/product-registration/unit-list',
        },
      ],
    },
    {
      title: 'General Registration',
      key: 'generalRegistration',
      icon: 'fe fe-box',
      children: [
        {
          title: 'Warehouse',
          key: 'genRegWarehouse',
          url: '/general-registration/warehouse',
        },
        {
          title: 'Warehouse List',
          key: 'genRegWarehouseList',
          url: '/general-registration/warehouse-list',
        },
        {
          title: 'Service',
          key: 'genRegService',
          url: '/general-registration/service',
        },
        {
          title: 'Service List',
          key: 'genRegServiceList',
          url: '/general-registration/service-list',
        },
      ],
    },
  ]
}
