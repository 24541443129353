import { all, call, put, takeLatest } from 'redux-saga/effects'
import getUsers from 'services/user'
import userActions from './actions'

export function* GET_USERS() {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      users: [],
      loadingUsers: true,
    },
  })
  const response = yield call(getUsers)
  if (response) {
    yield put({
      type: userActions.SET_STATE,
      payload: {
        users: response.data.users,
      },
    })
  }
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loadingUsers: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeLatest(userActions.GET_USERS, GET_USERS)])
}
