import React from 'react'
import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          Copyright © 2020{' '}
          <a href="https://xufire.com" target="_blank" rel="noopener noreferrer">
            Xufire
          </a>{' '}
          | All rights reserved.
        </p>
      </div>
    </div>
  )
}

export default Footer
