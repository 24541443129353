import { all, call, put } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import menuActions from './actions'

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: menuActions.SET_STATE,
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
