import { all } from 'redux-saga/effects'
import menu from './menu/sagas'
import settings from './settings/sagas'
import authUser from './authUser/sagas'
import user from './user/sagas'
import accountAccessGroup from './registration/account-registration/account-access-group/sagas'
import chartOfAccounts from './registration/chart-of-accounts/sagas'
import bank from './registration/account-registration/bank-account/sagas'
import region from './party-registration/region/sagas'
import area from './party-registration/area/sagas'
import sector from './party-registration/sector/sagas'
import client from './party-registration/client/sagas'
import supplier from './party-registration/supplier/sagas'
import mainUnit from './registration/product-registration/main-unit/sagas'
import warehouse from './registration/general-registration/warehouse/sagas'
import service from './registration/general-registration/service/sagas'

export default function* rootSaga() {
  yield all([
    authUser(),
    menu(),
    settings(),
    user(),
    accountAccessGroup(),
    chartOfAccounts(),
    bank(),
    region(),
    area(),
    sector(),
    client(),
    supplier(),
    mainUnit(),
    warehouse(),
    service(),
  ])
}
