import regionActions from './actions'

const initialState = {
  regionList: [],
  loadingRegionList: false,
  editableRegion: {},
  loadingEditableRegion: false,
  regionDetails: {},
  loadingRegionDetails: false,
  regionsLookup: [],
  loadingRegionsLookup: false,
  selectedRegionLookup: {},
  totalDataCount: 0,
  savingStatus: '',
}

export default function regionReducer(state = initialState, action) {
  switch (action.type) {
    case regionActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
