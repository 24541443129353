const aagActions = {
  SET_STATE: 'aag/SET_STATE',
  ADD_AAG: 'aag/ADD_AAG',
  UPDATE_AAG: 'aag/UPDATE_AAG',
  DELETE_AAG: 'aag/DELETE_AAG',
  GET_AAG_LIST: 'aag/GET_AAG_LIST',
  GET_EDITABLE_AAG: 'aag/GET_EDITABLE_AAG',
  RESET_EDITABLE_AAG: 'aag/RESET_EDITABLE_AAG',
  GET_AAG_DETAILS: 'aag/GET_AAG_DETAILS',
  RESET_AAG_DETAILS: 'aag/RESET_AAG_DETAILS',
  GET_AAGS_LOOKUP: 'aag/GET_AAGS_LOOKUP',
  SET_SELECTED_AAG_LOOKUP: 'aag/SET_SELECTED_AAG_LOOKUP',
  RESET_SAVING_STATUS: 'aag/RESET_SAVING_STATUS',
}

export default aagActions
