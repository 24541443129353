import bankActions from './actions'

const initialState = {
  bankAccountList: [],
  loadingBankAccountList: false,
  bankAccountDetails: {},
  loadingBankAccountDetails: false,
  editableBankAccount: {},
  loadingEditableBankAccount: false,
  totalBankAccountsDataCount: 0,
  savingStatus: '',
}

export default function bankReducer(state = initialState, action) {
  switch (action.type) {
    case bankActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
