const bankActions = {
  SET_STATE: 'bank/SET_STATE',
  ADD_BANK_ACCOUNT: 'bank/ADD_BANK_ACCOUNT',
  UPDATE_BANK_ACCOUNT: 'bank/UPDATE_BANK_ACCOUNT',
  DELETE_BANK_ACCOUNT: 'bank/DELETE_BANK_ACCOUNT',
  GET_BANK_ACCOUNT_LIST: 'bank/GET_BANK_ACCOUNT_LIST',
  GET_BANK_ACCOUNT_DETAILS: 'bank/GET_BANK_ACCOUNT_DETAILS',
  RESET_BANK_ACCOUNT_DETAILS: 'bank/RESET_BANK_ACCOUNT_DETAILS',
  GET_EDITABLE_BANK_ACCOUNT: 'bank/GET_EDITABLE_BANK_ACCOUNT',
  RESET_EDITABLE_BANK_ACCOUNT: 'bank/RESET_EDITABLE_BANK_ACCOUNT',
  RESET_SAVING_STATUS: 'bank/RESET_SAVING_STATUS',
}

export default bankActions
