import mainUnitActions from './actions'

const initialState = {
  list: [],
  loadingList: false,
  editable: {},
  loadingEditable: false,
  details: {},
  loadingDetails: false,
  lookup: [],
  loadingLookup: false,
  selectedLookup: {},
  totalDataCount: 0,
  savingStatus: '',
}

export default function mainUnitReducer(state = initialState, action) {
  switch (action.type) {
    case mainUnitActions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
